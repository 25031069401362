<template>
  <w-tables
      :noDataTitle="$t('table.no_data_title')"
      :noDataSubtitle="$t('table.no_data_subtitle')"
      :items="order"
      :fields="computedFields"
      :per-page="10000"
      :custimized-items="[
                { name: 'name' },
                { name: 'price' },
                { name: 'total' },
                { name: 'options' },
                { name: 'quantity' },
              ]"
  >
    <template slot="name" slot-scope="{ data }">
      <div class="order-table_name" :class="editOrder?'cursor-pointer':''" @click="$emit('onCartEdit',data.item)">

        <name-tile
            :name="data.item.name"
            :image="data.item.image"
        />
        <div v-if="editOrder" class="edit">{{ $t('common.edit') }}</div>
      </div>
    </template>


    <template slot='options' slot-scope='{ data }'>
      <div>

        <div v-for='option in data.item.options'>
          <div>
            {{ option.name }}
          </div>
        </div>

      </div>
    </template>





    <template slot="price" slot-scope="{ data }">
      <check-price
          :price="data.item.old_price"
          :dis-price="data.item.price"
      />
    </template>

    <template slot="total" slot-scope="{ data }">
      <check-price
          :price="data.item.total"
      />
    </template>

    <template slot="quantity" slot-scope="{ data }">
      x{{ data.item.quantity }}
    </template>


  </w-tables>
</template>

<script>


import NameTile from "@/components/nameTile.table";
import CheckPrice from "@/components/checkPrice";
import CheckValue from "@/components/checkValue";
import {WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm';

export default {
  components: {
    CheckValue,
    CheckPrice,
    NameTile,
    WTables,
  },
  props: {
    order: [],
    tableFields: [],
    editOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },

        {
          key: 'name',
          label: this.$i18n.t('products.table.name'),
          sortable: false,
          tdClass: 'w-3/8'
        },


        {
          key: 'order_type',
          label: this.$i18n.t('products.table.order_type'),
          sortable: false,
        },


        {
          key: 'count',
          label: this.$i18n.t('products.table.count'),
          sortable: false,
        },



        {
          key: 'options',
          label: this.$i18n.t('products.table.options'),
          sortable: false,
        },
        {
          key: 'quantity',
          label: this.$i18n.t('table.quantity'),
          sortable: false,
        },
        {
          key: 'price',
          label: this.$i18n.t('table.price'),
          sortable: false,
          tdClass: 'w-120px'
        },

        {
          key: 'total',
          label: this.$i18n.t('table.total'),
          sortable: false,
          tdClass: 'w-120px'
        },

      ],
    };
  },

  computed: {
    computedFields() {
      return this.fields.filter((item) => {
        return this.tableFields.includes(item.key);
      });
    }
  }


}
;
</script>
