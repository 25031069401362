<template>
  <div class="product-product-card" @click="onClick">

    <div class="product-info">
      <div class="data">

        <img v-if="data.image && showImage" :src=data.image :alt=data.name @error="onError" class="person-img">
        <div v-else class="person-img">
      <span>
        {{ trimName[0] }}
     </span>
        </div>

        <div class="col">
          <div class="name">{{ data.name }}</div>
          <div class="options">
            <span v-if='data.has_options'>{{ $t('orders.products.multi') }}</span>
            <span v-else>----------</span>
          </div>
          <div class="text" >
            <check-price
                :class="'price'"
                :price="data.price"
                :dis-price="calcDiscount"

            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckPrice from "@/components/checkPrice";

export default {
  components: {CheckPrice},

  props: {
    data: null
  },
  data() {
    return {
      showImage: true,
    }
  },



  computed: {
    trimName() {
      if (this.data.name) {
        return this.data.name.toString().trim();
      }
      return '';
    },
    calcDiscount(){
      if(this.data.price && this.data.discount){
        return this.data.price - (this.data.price * (this.data.discount / 100));
      }
      return 0;
    }

  },
  methods: {
    onError() {
      this.showImage = false;
    },
    onClick() {
      this.$emit('onClick');
    }
  }
}
;
</script>
